<template>
    <div v-if="isLoad">
        <template v-if="orderList.length > 0">
            <van-list class="order-list" v-model="loading" :finished="loadLastText" finished-text="到底啦〜想不到你看到了这里^_^"
                @load="getMore">
                <order-item v-for="(item, index) in orderList" :key="index" :item="item" :from="from"
                    @changeList="changeList"></order-item>
            </van-list>
        </template>
        <no-data v-else content="您还没有相关订单"></no-data>
    </div>
</template>

<script>
    import OrderItem from "./OrderItem";
    export default {
        name: "",
        components: {
            OrderItem
        },
        props: {
            orderList: {
                type: Array,
                default () {
                    return [];
                }
            },
            loadLastText: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            isLoad: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            from: {
                type: String,
                default () {
                    return "order";
                }
            }
        },
        data() {
            return {
                loading: false
            };
        },
        methods: {
            getMore() {
                this.$emit("getMore");
            },
            changeList() {
                this.$emit("changeList");
            },
            changeLoading() {
                this.loading = false;
            }
        }
    };
</script>

<style scoped lang="scss">
    .order-list {
        width: 100vw;
        overflow-y: hidden;
        padding: 4vw 3vw;
        box-sizing: border-box;

        .null-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;

            img {
                width: 60vw;
                margin-bottom: 0.5rem;
            }

            p {
                font-size: 0.26rem;
                color: #afafaf;
            }
        }
    }
</style>
