<template>
    <div class="order-grid">
        <div class="order-hd">
            <div class="order-num">订单编号：{{ item.order_no || item.service_no }}</div>
            <div class="order-status" v-if="statusInfo">{{ statusInfo.statusText }}</div>
        </div>
        <div class="goods-list">
            <div class="goods-grid">
                <img :src="item.goods_image" alt />
                <div class="goods-info">
                    <p class="goods-name">{{ item.goods_name }}</p>
                    <div class="goods-spec" v-if="item.specs_item_value != ''">{{ item.specs_item_value }}</div>
                    <div class="row">
                        <div class="goods-price">￥{{ item.goods_price }}</div>
                        <div class="goods-num">×{{ item.goods_nums }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-bot">
            <template v-if="statusInfo && statusInfo.showBtn">
                <template v-for="(item_, index_) in statusInfo.btnArr">
                    <div :class="['btn',{'red': item_.isActive}]" :key="index_" v-if="item_.isShow"
                        @click="action(item_.types)">{{ item_.text }}</div>
                </template>
            </template>
            <router-link :to="'/ShopMallOrderDetails?order_id=' + item.order_id" class="btn" v-if="from == 'order'">订单详情
            </router-link>
            <router-link :to="'/AfterSaleOrderDetails?service_id=' + item.service_id" class="btn" v-if="from == 'after'">售后详情
            </router-link>
        </div>
    </div>
</template>

<script>
    //import { actionOrder} from "@/common/data";
    import {
        closeGoodsOrder,
        receiveGoodsOrder,
        deleteGoodsOrder
    } from "@/http/api";
    export default {
        name: "",
        components: {},
        props: {
            item: {
                type: Object,
                default () {
                    return {};
                }
            },
            from: {
                type: String,
                default () {
                    return "order";
                }
            }
        },
        data() {
            return {
                userToken: "",
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
        },
        computed: {
            statusInfo() {
                if (this.from == 'order') {
                    return this.orderStatus(this.item)
                } else if (this.from == 'after') {
                    return this.afterStatus(this.item)
                }
            }
        },

        methods: {
            orderStatus(details) {
                let status = details.order_status,
                    isService = details.is_service;
                switch (status) {
                    case 0:
                        return {
                            statusText: '已取消',
                                icon: 'tranbg',
                                showBtn: true,
                                btnArr: [{
                                    isActive: false,
                                    text: '删除订单',
                                    types: 'delete1',
                                    isShow: true
                                }]
                        }
                        break;
                    case 1:
                        return {
                            statusText: '待付款',
                                icon: 'tranbg2',
                                showBtn: true,
                                btnArr: [{
                                    isActive: true,
                                    text: '立即支付',
                                    types: 'toPay',
                                    isShow: false
                                }, {
                                    isActive: false,
                                    text: '取消订单',
                                    types: 'cancel',
                                    isShow: true
                                }]
                        };
                        break;
                    case 2:
                        let text = '申请售后',
                            types = 'after';
                        if (isService == 2) {
                            text = '已申请';
                            types = 'hasAfter';
                        }
                        return {
                            statusText: '待发货',
                                icon: 'tranbg3',
                                showBtn: true,
                                btnArr: [{
                                    isActive: false,
                                    text,
                                    types,
                                    isShow: true
                                }]
                        };
                        break;
                    case 3:
                        let text_ = '申请售后',
                            types_ = 'after',
                            isShow = true;
                        if (isService == 2) {
                            text_ = '已申请';
                            types_ = 'hasAfter';
                            isShow = false
                        }
                        return {
                            statusText: '待收货',
                                icon: 'tranbg4',
                                showBtn: true,
                                btnArr: [{
                                    isActive: true,
                                    text: '确认收货',
                                    types: 'toReceive',
                                    isShow
                                }, {
                                    isActive: false,
                                    text: text_,
                                    types: types_,
                                    isShow: true
                                }]
                        };
                        break;
                    case 4:
                        return {
                            statusText: '已完成',
                                icon: 'tranbg1',
                                showBtn: false,
                                btnArr: [{
                                    isActive: false,
                                    text: '删除订单',
                                    types: 'delete1',
                                    isShow: true
                                }]
                        };
                        break;
                    default:
                        return {
                            statusText: '未知',
                                icon: 'tranbg',
                                showBtn: false,
                        }
                }
            },

            afterStatus(details) {
                let status = details.service_status,
                    isService = details.is_service;
                switch (status) {
                    case 0:
                        return {
                            statusText: '已取消',
                                icon: 'tranbg',
                                showBtn: false,
                        }
                        break;
                    case 1:
                        return {
                            statusText: '申请中',
                                icon: 'tranbg2',
                                showBtn: false
                        }
                        break;

                    case 2:
                        break;

                    case 3:
                        break;

                    case 4:
                        return {
                            statusText: '待处理',
                                icon: 'tranbg3',
                                showBtn: false
                        }
                        break;
                    case 5:
                        return {
                            statusText: '已完成',
                                icon: 'tranbg1',
                                showBtn: false,
                        }
                        break;
                    default:
                        return {
                            statusText: '未知',
                                icon: 'tranbg',
                                showBtn: false,
                        }
                        break;
                }
            },

            action(types) {
                switch (types) {
                    case 'delete1':
                        this.$dialog.confirm({
                            title: '提示',
                            message: '确定要删除该订单吗？'
                        }).then(() => {
                            this.deleteGoodsOrder()
                        })
                        break;
                    case 'cancel':
                        this.$dialog.confirm({
                            title: '提示',
                            message: '确定要取消该订单吗？'
                        }).then(() => {
                            this.closeGoodsOrder()
                        })
                        break;
                    case 'toReceive':
                        this.$dialog.confirm({
                            title: '提示',
                            message: '请确保您已收到货物，收货后如需售后请联系客服',
                            confirmButtonText: '收到了',
                            cancelButtonText: '没收到',
                        }).then(() => {
                            this.receiveGoodsOrder()
                        })
                        break;
                    case 'after':
                        this.$router.push({
                            path: '/AfterSale?order_id=' + this.item.order_id
                        });
                        break;
                    case 'hasAfter':
                        break;
                    case 'toPay':
                        break;
                }
            },

            async deleteGoodsOrder() {
                const res = await deleteGoodsOrder({
                    data: {
                        user_token: this.userToken,
                        order_id: this.item.order_id
                    }
                });
                if (res.code == 200) {
                    this.$toast.success('删除成功');
                    this.$emit("changeList");
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            async closeGoodsOrder() {
                const res = await closeGoodsOrder({
                    data: {
                        user_token: this.userToken,
                        order_id: this.item.order_id
                    }
                });
                if (res.code == 200) {
                    this.$toast.success('取消成功');
                    this.$emit("changeList");
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            async receiveGoodsOrder() {
                const res = await receiveGoodsOrder({
                    data: {
                        user_token: this.userToken,
                        order_id: this.item.order_id
                    }
                });
                if (res.code == 200) {
                    this.$toast.success('确认成功');
                    this.$emit("changeList");
                } else {
                    this.$toast.fail(res.msgs);
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .order-grid {
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        padding: 0 1vw;
        margin-bottom: 0.2rem;

        .order-hd {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 0.84rem;
            font-size: 0.28rem;
            border-bottom: 1px solid #eee;

            .order-status {
                color: #da1313;
            }
        }

        .goods-list {
            border-bottom: 1px solid #eee;
        }

        .goods-grid {
            height: 28vw;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                width: 24vw;
                height: 24vw;
                object-fit: cover;
            }

            .goods-info {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 24vw;
                width: 64vw;

                .goods-name {
                    font-size: 0.26rem;
                    color: #323233;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .goods-spec {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    background: #f5f5f5;
                    border-radius: 5px;
                    padding: 0.1rem 0.15rem;
                    font-size: 0.24rem;
                    color: #848484;
                    // width: max-content;
                }

                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .goods-price {
                        font-size: 0.3rem;
                        font-weight: bold;
                        color: #da1313;
                    }

                    .goods-num {
                        font-size: 0.24rem;
                    }
                }
            }
        }

        .order-bot {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 1.13rem;

            .btn {
                width: 1.4rem;
                height: 0.6rem;
                border-radius: 5px;
                border: 1px solid #c0c0c0;
                color: #585858;
                font-size: 0.26rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.35rem;
            }

            .btn.red {
                color: #da1313;
                border: 1px solid #da1313;
            }
        }
    }
</style>
